<script setup>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import router from '@/router'
import { returnLanguage } from '../../assets/js/language'
import AboutBanner from './components/about-banner'
import { getInfo, getList } from '@/api/new'
import { format } from '@/utils/utils'

const store = useStore()
// 拿到vuex中的分类列表
const langtype = store.state.home.type
// eslint-disable-next-line no-undef
const langvalue = ref(returnLanguage(langtype))
// console.log(langvalue.value.top.one, '测试')

const type = ref(langtype)

let route = useRoute()
let id = ref(route.query.id)
let cateId = ref(route.query.cateId)
let productList = ref([])
let content = ref('')
let title = ref('')
let times = ref('')
let getInfoK = async (id) => {
  let { data } = await getInfo({ id })
  content.value = data.content
  title.value = data.title
  times.value = format(data.create_time * 1000, 'yyyy-MM-dd HH:mm:ss')
}
getInfoK(id.value)
const getNewList = () => {
  getList(1, 100, cateId.value).then(res => {
    let {
      data: { list }
    } = res
    productList.value = list
  })
}
getNewList()
const goInfo = id => {
  getInfoK(id)
  window.scrollTo({ top: 0, behavior: 'smooth' }); // 平滑滚动至顶部

}

watch(
  () => store.state.home.type,
  newVal => {
    langvalue.value = returnLanguage(newVal)
    type.value = newVal
    getInfoK(id.value)
    getNewList()
  },
  { immediate: true }
)
</script>
<template>
  <div class="connew">
    <AboutBanner />
    <div class="new_main">
      <!-- <div class="zx_title">
        <img class="icon" src="@/assets/images/zx_icon.png" alt="" />
        <p>{{ langvalue.news.five }} > {{ langvalue.news.four }}</p>
      </div> -->
      <div class="box">
        <div class="info_title">
          {{ title }}
        </div>
        <div class="s_title">{{ langvalue.news.six }} | {{ times }}</div>
      </div>
      <div v-if="content">
        <div class="new_info" v-html="content"></div>
      </div>
      <div v-else class="new_info"></div>

      <div class="new_list">
        <div class="_list_title">
          <span>{{ langvalue.other.seven }}</span>
        </div>
        <div
          class="new_item"
          v-for="(item, index) in productList"
          :key="index"
          @click="goInfo(item.id)"
        >
          <div class="card_img">
            <img
              :src="'https://admin.wukongbc.com/' + item.thumb"
              class="cover"
            />
          </div>
          <div class="content">
            <div class="store">{{ item.title }}</div>
            <div class="time">
              {{ format(item.create_time * 1000, 'yyyy-MM-dd') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.connew {
  background: #f8f8f8;
  .new_main {
    background: #fff;
    width: 1340px;
    padding: 20px 100px 200px;
    margin: -280px auto 0;

    .box {
      .info_title {
        font-size: 40px;
        color: #333;
        margin-top: 40px;
      }
    }
    .zx_title {
      display: flex;
      align-items: center;
      .icon {
        width: 40px;
        height: 40px;
        margin-right: 14px;
      }
      p {
        color: #666;
        font-family: 'PingFang SC';
        font-size: 24px;
        font-style: normal;
      }
    }
    .new_info {
      min-height: 300px;
      padding: 40px 0 80px;
    }
    .new_list {
      // border: 1px solid red;
      ._list_title {
        border-bottom: 1px solid #d3d5e0;
        line-height: 28px;
        padding: 8px 0;
        span {
          color: rgba(0, 0, 0, 0.8);
          padding: 2px 0;
          font-family: 'PingFang SC';
          font-size: 50px;
          font-style: normal;
          font-weight: 400;
          border-bottom: 2px solid #0188ff;
        }
      }

      .new_item {
        padding: 44px 0;
        display: flex;
        align-items: center;
        border-radius: 4px;
        background: #fff;
        box-sizing: border-box;
        border-bottom: 1px solid #d3d5e0;

        .card_img {
          margin-right: 40px;
          .cover {
            border-radius: 30px;
            width: 400px;
            height: 205px;
            background: #000;
          }
          // margin-bottom: 7px;
          &.active {
            border: 1px solid #e7e7e7;
          }
          // :deep(.lazy__img) {
          //   width: 100%;
          //   border-radius: 4px;
          //   font-size: 0;
          //   height: 100%;
          // }
        }
        .content {
          flex: 1;
          padding: 0 8px;
          .store {
            color: #000;
            font-size: 28px;
            font-weight: 600;
            line-height: 50px;
          }
          .time {
            color: #000;
            font-size: 20px;
            line-height: 60px;
          }
          .title {
            color: #707070;
            font-size: 18px;
            font-weight: 400;
            line-height: 35px;
          }
        }
      }
    }
  }
}
</style>
